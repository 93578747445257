@import '../../../resources/scss/_main.scss';

@media only screen and (max-width: 1229px) {
  .ProfileCircle {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    margin: auto auto 5%;
  }

  .ProfileImg {
    width: 100%;
    height: auto;
  }

  .TitleFont {
    display: block;
    padding-top: 45px;
    font-family: $title-font-family;
    font-size: $title-font-size-mobile;
    color: $title-font-color;
    text-align: center;
  }

  .TitleCircle {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    background: rgba(0, 0, 0, 0.3);
    margin: auto auto 20%;
  }

  .NameFont {
    display: block;
    padding-top: 45px;
    font-family: $title-font-family;
    font-size: $title-font-size-mobile;
    color: $title-font-color;
    text-align: center;
  }

  .NameCircle {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    background: rgba(0, 0, 0, 0.3);
    margin: 5px auto 5%;
  }

  .icons {
    color: white;

    &:hover {
      color: #48ff00
    }
  }

  .iconsContainer {
    position: relative;
    max-width: 300px;
    margin: auto;
  }

  .facebookIcon {
    position: absolute;
    left: 0;
    top: 25px;
  }

  .linkedInIcon {
    position: absolute;
    left: 0;
    top: 140px;
  }

  .githubIcon {
    position: absolute;
    right: 0;
    top: 25px;
  }

  .youtubeIcon {
    position: absolute;
    right: 0;
    top: 140px;
  }
}
