.socialMediaContainer {
  display: flex;
  justify-content: center;
  padding-right: 25px;
}

.socialMediaIcons {
  color: white;
  margin: 8px;

  &:hover {
    color: #48ff00
  }
}

@media (max-width: 750px) {
  .socialMediaContainer {
    display: flex;
    justify-content: center;
    padding-left: 25px;
  }
}