@import '../../resources/scss/loader';

@mixin flexContainer ($direction) {
  display: flex;
  justify-content: center;
  flex-direction: $direction;
  @include photos($p-btm: 0px)
}

@mixin photos ($p-btm: 0px, $p-left: 0px, $p-top: 0px) {
  .photos {
    padding: $p-top 25px $p-btm $p-left;

    img {
      width: 100%;
      height: 100%;
      border: 1px solid white;
      border-radius: 15px;
      box-shadow: 25px 25px 30px 0px rgba(0, 0, 0, 1);
    }
  }
}

.aboutContainer {
  @include flexContainer ($direction: row);
  @include photos($p-btm: 0px, $p-left: 25px);
}



@media (max-width: 750px) {
  .aboutContainer {
    @include flexContainer ($direction: column);
    @include photos($p-btm: 15px, $p-left: 25px, $p-top: 25px)
  }
}

.loadingBox {
 width: 200px;
}

.socialMediaContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
}

.socialMediaIcons {
  color: white;
  margin: 8px;

  &:hover {
    color: #48ff00
  }
}