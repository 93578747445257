@import url('https://fonts.googleapis.com/css?family=Sora&effect=emboss|outline');
@import './resources/scss/_main.scss';

* {
  box-sizing: border-box;
}

.backdrop {
  min-height: 100vh;
  background-size: auto;
  background-image: url('./resources/images/annie-spratt-gM8igOIP5MA-unsplash.jpg');
  background-repeat: repeat;
}